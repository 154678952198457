@import "assets/styles/variables.modules";
@import "assets/styles/mixins.modules";

.recipe {
    width: 100%;
    display: block;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-self: center;

    @media print {
        width: 100%;
        max-height: 1000px;
    }

    ul {
        width: 100%;
        justify-content: center;

        @media print {
            border-top: 3px solid black;
            width: 100%;
            padding-top: 30px;
        }
    }
}

.wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

    @media print {
        justify-content: center;
        background: white;
        align-items: center;
        align-self: center;
        width: 90%;
    }
}

.buttons {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    align-items: center;

    &Content {
        min-width: 140px;
        margin: 0 50px;

        @media print {
            display: none;
        }

        button {
            min-width: 140px;
        }
    }
}

.print {
    display: flex;
    flex-direction: column;
    width: 100%;

    @media print {
        background: white;
        height: 100%;
    }
}

.brakePage {
    display: block;
    //page-break-before: always;
}

.container {
    display: flex;
    flex-direction: column;

    @include breakpoint(lg) {
        flex-direction: row;
    }

    div {
        margin: 5px 0;

        @include breakpoint(lg) {
            margin: 0 20px;
        }
    }
}
