@import "../../../src/assets/styles/grid.modules";
@import "../../../src/assets/styles/variables.modules";

.header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    &Wrapper {
        justify-content: space-between;
        display: flex;
        flex-direction: row;
        font-weight: bold;
        width: 80%;
        background: $menu-background;
        border-radius: 10px;
        margin: 10px -14px;
    }

    &Logo {
        display: flex;
        justify-content: center;
        align-self: center;
        height: 100%;
        margin-left: 30px;
        flex-direction: column;

        &Content {
            margin-bottom: 10px;
            height: 70px;
        }
    }

    &Item {
        @include texts(large);

        margin: 15px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        span {
            margin: 0 0 10px;
            @include texts(2x-large);
        }
    }

    &List {
        list-style-type: none;
        padding: 0;
        flex-direction: column;
        display: flex;
        align-items: flex-start;
        margin-top: 10px;

        a {
            @include texts(big);

            margin: 20px 0;
            padding-bottom: 3px;

            &:hover {
                border-bottom: 2px solid $font-primary--hover;
            }
        }
    }

    &SubItem {
        margin-bottom: 6px;

        @include texts(big);
    }

    &User {
        @include texts(large);

        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 45px;
        width: 20%;

        &Name {
            @include fontWeight(large);
        }

        &Title {
            @include fontWeight(normal);
        }

        &Icon {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 10px;

            &Content {
                background: $iconBackground;
                padding: 1px;
                border-radius: 10px;
                margin-right: 10px;
                width: 24px;

                svg {
                    fill: red;
                }
            }
        }
    }
}

.mobile {
    width: 100%;
    background-color: $table-header;
    margin-bottom: 30px;

    &Wrapper {
        display: flex;
        align-items: center;
        align-self: center;
        justify-content: space-around;
        text-align: center;
        height: 50px;
    }

    &Logo {
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        @include fontWeight(bolded);

        img {
            height: 30px;
            margin-right: 10px;
        }
    }

    button {
        height: 40px;
    }

    &Menu {
        background: $iconBackground;
        min-height: 50px;
        position: absolute;
        width: 100%;
        border-bottom: 2px black solid;
        z-index: 10000;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &Close {
            position: absolute;
            top: 15px;
            right: 15px;
        }
    }

    &Nested {
        display: flex;
        margin-left: 10px;
        flex-direction: column;

        &Item {
            margin: 2px 5px;

            @include texts(large);
            @include fontWeight(large);
        }
    }

    &List {
        display: flex;
        flex-direction: column;
        margin-left: 10px;

        &Item {
            color: $black;
            margin: 2px 0;

            @include texts(x-large);
            @include fontWeight(bolded);

            a {
                color: #505b67;
            }
        }

        &Wrapper {
            display: flex;
            flex-direction: column;
        }
    }

    &Icon {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px;
        width: 20px;
        height: 20px;
        background: $iconBackground;
        border-radius: 8px;
        margin-right: 5px;

        img {
            width: 18px;
            height: 18px;
            background: $iconBackground;
            margin-left: 0;
        }
    }
}
